var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-box-right"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account',
                { rules: [{ required: true, message: '请输入用户名!' }] } ]),expression:"[\n                'account',\n                { rules: [{ required: true, message: '请输入用户名!' }] },\n              ]"}],attrs:{"placeholder":"用户名","allowClear":true}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                { rules: [{ required: true, message: '请输入密码!' }] } ]),expression:"[\n                'password',\n                { rules: [{ required: true, message: '请输入密码!' }] },\n              ]"}],attrs:{"type":"password","placeholder":"密码","allowClear":true}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['captcha',{ rules: [{ required: true, message: '请输入密码!' }] } ]),expression:"['captcha',{ rules: [{ required: true, message: '请输入密码!' }] },]"}],attrs:{"type":"text","placeholder":"验证码","allowClear":true}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1),_c('img',{attrs:{"id":"captcha","src":_vm.captcha_img,"alt":"二维码"},on:{"click":function($event){return _vm.refreshImg()}}})],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                } ]),expression:"[\n                'remember',\n                {\n                  valuePropName: 'checked',\n                  initialValue: true,\n                },\n              ]"}]},[_vm._v(" 记住密码 ")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 点击登录 ")]),_c('div',{staticClass:"reg-txt"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }